<template>
  <div class="categories-page px-2">
    <div class="title font-weight-bold h5 text-center my-4 mt-2">اكتشف</div>
    
    <div class="vertical-scroll hide-scrollbar ps-4" v-if="$apollo.queries.categories.loading">
        <CategoryCardLoader />
    </div>

    <div class="row justify-content-md-center text-center text-light mx-0 px-3">
        <div class="col-4 col-md-4" v-for="category in categories" :key="category.id">
            <CategoryCard :category="category" />
        </div>
    </div>
  </div>
</template>

<script>
import CategoryCard from "../../components/CategoryCard.vue";
import gql from "graphql-tag";
import CategoryCardLoader from '../../components/Loader/CategoryCardLoader.vue';

export default {
  components: { CategoryCard, CategoryCardLoader },

  apollo: {
    categories: {
      query: gql`
        query  {
          categories {
            id
            name_ar
            name_en
            icon_url
          }
        }
      `,
    },
  },
  data() {
    return {
      categories: [],
    }
  },
};
</script>

<style>

</style>
